import React, { useState } from "react";
import "./Contact.css";
import { motion } from "framer-motion";
import { fadeIn } from "../../src/framerMotion/variant.jsx";
import { FaPhoneSquareAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { IoIosHome } from "react-icons/io";
import { FaLinkedin, FaInstagram } from "react-icons/fa";

const Contact = () => {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus("Sending...");

    try {
      const response = await fetch("http://localhost:5000/contact", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ name, phone, email, message }),
      });

      if (response.status === 200) {
        setStatus("Message sent successfully!");
        setName("");
        setPhone("");
        setEmail("");
        setMessage("");
      } else {
        setStatus("Error sending message.");
      }
    } catch (error) {
      setStatus("Error sending message.");
    }
  };

  return (
    <div className="contact-container">
      <h2 className="columns">Contact Us</h2>
      <div className="contact-content">
        {/* Left-side Contact Details */}
        <motion.div
          variants={fadeIn("right", 0.3)}
          initial="hidden"
          whileInView="show"
          viewport={{ once: false, amount: 0.7 }}
          className="contact-details"
        >
          <h2>Contact Details</h2>
          <p className="Date">
            <strong><FaPhoneSquareAlt size={24} className="cont" />  </strong> <a href="tel:+919565345676">+91 85273 45379</a>
          </p>
          <p className="Date">
            <strong><MdEmail size={24} className="cont"/>  </strong><a href="mailto:techjobmentor@gmail.com ">
              {" "}
              techjobmentor@gmail.com{" "}
            </a>
          </p>
          <p className="Date">
            <strong><IoIosHome size={24} className="cont"  /> </strong> Plot No 20, 21, Noida-Greater Noida Expy, Sector 135, Noida, Uttar Pradesh 201304, India 
          </p>
           <div className="social-icons-1">
                      <a
                        href=" https://www.linkedin.com/in/tech-mentors?"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FaLinkedin size={24} />
                      </a>
                      <a
                        href=" https://www.instagram.com/techmentors_/profilecard/?igsh=YW9hNnlkcjlsaXU4 "
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FaInstagram size={24} />
                      </a>
                    </div>
        </motion.div>
        

        {/* Right-side Get in Touch Form */}
        <motion.div
          variants={fadeIn("left", 0.3)}
          initial="hidden"
          whileInView="show"
          viewport={{ once: false, amount: 0.7 }}
          className="contact-form"
        >
          <h2>Get in Touch</h2>
          <form onSubmit={handleSubmit}>
            <div>
              <label className="contact-data">Name:</label>
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>
            <div>
              <div>
                <label className="contact-data">Phone No. :</label>
                <input
                  type="tel"
                  maxlength="10"
                  pattern="[0-9]{10}"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  required
                />
              </div>
              <label className="contact-data">Email:</label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div>
              <label className="contact-data">Message:</label>
              <textarea
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                required
              ></textarea>
            </div>
            <button type="submit">Submit</button>
          </form>
          {status && <p>{status}</p>}
        </motion.div>
      </div>
    </div>
  );
};

export default Contact;
