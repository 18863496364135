import React, { useState } from "react";
import "./Service.css";
import serviceImage1 from "../assets/linux.png";
import serviceImage2 from "../assets/plsql.png";
import serviceImage3 from "../assets/pyth.jpg";
import serviceImage4 from "../assets/aws.png";
import serviceImage5 from "../assets/perl.png";
import serviceImage6 from "../assets/tools.png";
import serviceImage7 from "../assets/software-architect.jpg";
import serviceImage8 from "../assets/mobile-app.jpg";
import serviceImage9 from "../assets/Site-realiablity.jpg";
import serviceImage10 from "../assets/cybersecurity.jpg";
import serviceImage11 from "../assets/big.png";
import serviceImage12 from "../assets//machine-learning.jpg";
import serviceImage13 from "../assets/data-architecht.jpg";
import serviceImage14 from "../assets/database-admin.jpg";
import serviceImage15 from "../assets//CTO.jpg";
import call from "../assets//calls.jpg";
import resume from "../assets//resume.jpg";
import interview from "../assets/int.jpg";
import user from "../assets/user.png";
import mock from "../assets/mock1.jpg";
import clock from "../assets/clock.png";
import dev from "../assets/dev.jpg";
import tel from "../assets/real.png";
import learn from "../assets/learn.png";
import comunity from "../assets/discussion.jpg"

import { motion } from "framer-motion";
import { fadeIn } from "../../src/framerMotion/variant.jsx";

const services = [
  {
    id: 1,
    name: "Application Support Engineer",
    image: serviceImage1,
    link: "#application ",
  },
  {
    id: 2,
    name: "Backend Developer",
    image: serviceImage2,
    link: "#backend",
  },
  {
    id: 3,
    name: "Python Developer",
    image: serviceImage3,
    link: "#python",
  },
  {
    id: 4,
    name: "cloud Engineer",
    image: serviceImage4,
    link: "#cloud ",
  },
  {
    id: 5,
    name: "Frontend Developer",
    image: serviceImage5,
    link: "#frontend",
  },
  {
    id: 6,
    name: "DevOps Engineer",
    image: serviceImage6,
    link: "#tools",
  },
  {
    id: 7,
    name: "Software Architect",
    image: serviceImage7,
    link: "#software",
  },
  {
    id: 8,
    name: "Mobile App Developer",
    image: serviceImage8,
    link: "#mobile",
  },
  {
    id: 9,
    name: "Site Realiability Engineer",
    image: serviceImage9,
    link: "#site",
  },
  {
    id: 10,
    name: "Cyber Security Engineer",
    image: serviceImage10,
    link: "#cyber",
  },
  {
    id: 11,
    name: "Big Data Engineer",
    image: serviceImage11,
    link: "#big",
  },
  {
    id: 12,
    name: "Machine Learning",
    image: serviceImage12,
    link: "#machine",
  },
  {
    id: 13,
    name: "Data Architect",
    image: serviceImage13,
    link: "#data",
  },

  {
    id: 14,
    name: "DBA",
    image: serviceImage14,
    link: "#dba",
  },
  {
    id: 15,
    name: "Chief Technology Officer",
    image: serviceImage15,
    link: "#chief",
  },
];

const features = [
  {
    icon: call,
    title: "Placement Support",
    description:
      "Complete support from our dedicated placement team until you get a job.",
  },
  {
    icon: resume,
    title: "Resume Building",
    description: "We help students build professional, ATS-friendly resumes that highlight their skills and boost job prospects.",
  },
  
  {
    icon: interview,
    title: "Interview Preparation",
    description: "Professional interviews assistance.",
  },
  {
    icon: user,
    title: "Dedicated Career Coach",
    description: "Expert career coaches to guide you throughout the program.",
  },
  {
    icon: mock,
    title: "Mock Interview",
    description:
      " Mock interviews are incredibly valuable for candidates to build confidence and get constructive feedback.",
  },
  {
    icon: comunity,
    title: "Community",
    description: "Join our community and be a part of a lifelong network!",
  },
];

// ___________________________

// __________________________________________________-
const Service = () => {
  return (
    <section className="service-section">
      <motion.div
 variants={fadeIn("up", 0.3)}
 initial="hidden"
 whileInView="show"
 viewport={{ once: false, amount: 0.7 }}
className="course"
      >
        <h2>Our Courses</h2>
      </motion.div>
      <div className="service-container">
        {services.map((service) => (
          <div className="coursename"
          >
            <motion.div
              variants={fadeIn("down", 0.3)}
              initial="hidden"
              whileInView="show"
              viewport={{ once: false, amount: 0.7 }}
              className="service-card"
              key={service.id}
            >
              <div className="service-image">
                <img src={service.image} alt={service.name} />
              </div>
              <div className="service-content">
                <a href={service.link} className="service-link">
                  {service.name}
                </a>
              </div>
            </motion.div>
          </div>
        ))}
      </div>
      {/* --------my servises------- */}
      <div className="course">
        <h2>Our Services</h2>{" "}
      </div>
      <div className="feature-section">
        {features.map((feature, index) => (
          <div key={index} className="feature-item">
            <img src={feature.icon} alt={feature.title} />
            <h3>{feature.title}</h3>
            <p>{feature.description}</p>
          </div>
        ))}
      </div>
      {/* feauter _____________ */}

      <motion.div  
       variants={fadeIn("up", 0.3)}
       initial="hidden"
       whileInView="show"
       viewport={{ once: false, amount: 0.7 }}
      className="course">
        <h2>A Flexible Program Created for you</h2>
      </motion.div>
      <div className="faq-section">
        <motion.div 
         variants={fadeIn("right", 0.3)}
         initial="hidden"
         whileInView="show"
         viewport={{ once: false, amount: 0.7 }}
        className="faq-item">
          <img src={dev} alt="Code" />
          <h3>Develop skills for real career growth</h3>
          <p>
            Cutting-edge curriculum designed in guidance with industry and
            academia to develop job-ready skills
          </p>
        </motion.div>
        <motion.div
         variants={fadeIn("left", 0.3)}
         initial="hidden"
         whileInView="show"
         viewport={{ once: false, amount: 0.7 }}
        className="faq-item">
          <img src={learn} alt="Code" />
          <h3>
            Learn from experts active in their field, not out-of-touch trainers
          </h3>
          <p>
            Leading practitioners who bring current best practices and case
            studies to sessions that fit into your work schedule.
          </p>
        </motion.div>
        <motion.div 
         variants={fadeIn("right", 0.3)}
         initial="hidden"
         whileInView="show"
         viewport={{ once: false, amount: 0.7 }}
        className="faq-item">
          <img src={tel} alt="Code" />
          <h3>Learn by working on real-world problems</h3>
          <p>
            Projects involving real world data sets with virtual labs for
            hands-on learning
          </p>
        </motion.div>
        <motion.div 
         variants={fadeIn("left", 0.3)}
         initial="hidden"
         whileInView="show"
         viewport={{ once: false, amount: 0.7 }}
        className="faq-item">
          <img src={clock} alt="Code" />
          <h3>How will I resolve my doubts?</h3>
          <p>
            You can get your doubts resolved by connecting with our subject
            matter experts over text or video call!
          </p>
        </motion.div>
      </div>

      {/* ___________________carriculam section_________ */}
    </section>
  );
};

export default Service;
