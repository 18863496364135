import React from "react";
import { Link } from "react-router-dom";
import "./HomeStyle.css";
import About from "./About.jsx";
import Service from "./Service.jsx";
import Certificate from "./Certificate.jsx";
import Contact from "./Contact.jsx";
// import Google from "./Google.jsx";
import { motion } from "framer-motion";
import { fadeIn } from "../../src/framerMotion/variant.jsx";

const Home = () => {
  return (
    <>
      <div className="home">
        <div className="content">
          <motion.h4
            variants={fadeIn("up", 0.3)}
            initial="hidden"
            whileInView="show"
            viewport={{ once: false, amount: 0.7 }}
          >
           From Skills to Success – We Guide Your Journey
          </motion.h4>
          <Link to="/contact">
            <motion.button
              variants={fadeIn("right", 0.3)}
              initial="hidden"
              whileInView="show"
              viewport={{ once: false, amount: 0.7 }}
              className="btn"
            >
              CONTACT US
            </motion.button>
          </Link>
          <Link to="/about">
            <motion.button
              variants={fadeIn("left", 0.3)}
              initial="hidden"
              whileInView="show"
              viewport={{ once: false, amount: 0.7 }}
              className="button"
            >
              ABOUT{" "}
            </motion.button>
          </Link>
        </div>
      </div>
      <About />
      <Service />
      <Certificate />
      {/* <Google /> */}
      <Contact />
    </>
  );
};
export default Home;
