import { BrowserRouter, Router, Route, Routes } from "react-router-dom";
import "./styles.css";
import React from "react";
import Navbar from "./components/Navbar.jsx";
import Home from "./components/Home";
import About from "./components/About.jsx";
import Service from "./components/Service.jsx";
import Certificate from "./components/Certificate.jsx";
import Contact from "./components/Contact.jsx";
import Footer from "./components/Footer";
import Book from "./components/Book.jsx";
// import Google from "./components/Google.jsx";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/book" element={<Book />} />
          <Route path="/about" element={<About />} />
          <Route path="/service" element={<Service />} />
          <Route path="/certificate" element={<Certificate />} />
          {/* <Route path="/google" element={<Google />} /> */}
          <Route path="/contact" element={<Contact />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}
export default App;
